<!--  
	menu: instructions
-->



<script>

	// imports
	// ------------------------------------------------------------------------
	import string from '../Data/translations.js';
	import Menu from '../UI/Menu.svelte';

	// Add Umami Analytics script
    const script = document.createElement('script');
    script.defer = true;
    script.src = "https://cloud.umami.is/script.js";
    script.setAttribute("data-website-id", "38af22fa-8c4c-4122-ae72-767c084a366a");
    document.body.appendChild(script);

</script>




<Menu
	menuId="Instructions"
	contentClass="menu-article"
	longContent="{ true }"
>

	{@html string.instructionsContent}

</Menu>