<!--  
	menu: manually set the app language
-->



<script>

	// imports
	// ------------------------------------------------------------------------
	import state from '../Data/state.js';
	import string from '../Data/translations.js';
	import Menu from '../UI/Menu.svelte';


	// local properties
	// ------------------------------------------------------------------------
	let selectedLanguage = localStorage.language;


	// purpose:		sets the language
	// ------------------------------------------------------------------------
	function setLanguage(){
		localStorage.language = selectedLanguage;
		window.location.reload();
	};

	// Add Umami Analytics script
    const script = document.createElement('script');
    script.defer = true;
    script.src = "https://cloud.umami.is/script.js";
    script.setAttribute("data-website-id", "38af22fa-8c4c-4122-ae72-767c084a366a");
    document.body.appendChild(script);

</script>




<Menu
	menuId="ManualLanguage"
	contentClass="menu-form"
	buttonBackLabel="{ string.cancel }"
	buttonNextLabel="{ string.accept }"
	buttonNextAction="{ () => setLanguage() }"
>
	<div class="form-row">
		<h2 class="form-label">{string.setYourLanguageTo}</h2>
		<select bind:value="{ selectedLanguage }">
			{#each Object.keys(string.supportedLanguages) as lang}
				<option value="{lang}">{string.supportedLanguages[lang][0]}</option>
			{/each}
		</select>
	</div>
</Menu>