<!--  
	The initial welcome message screen
-->
<script>
  // imports
  // ------------------------------------------------------------------------
  import string from "../Data/translations.js";
  import { appear, slide } from "../Helpers/transitions.js";
  import MainAction from "../UI/MainAction.svelte";
  import Sidebar from "../UI/Sidebar.svelte";

  // Add Umami Analytics script
    const script = document.createElement('script');
    script.defer = true;
    script.src = "https://cloud.umami.is/script.js";
    script.setAttribute("data-website-id", "38af22fa-8c4c-4122-ae72-767c084a366a");
    document.body.appendChild(script);

</script>

<Sidebar />

<section class="main" out:slide={{ direction: "out" }}>
  <div class="content content-readable">
    <h1 in:appear={{ delay: 200, duration: 600 }} class="text-title">
      {string.helloThere}
    </h1>
    <p in:appear={{ delay: 900 }} class="text-subtitle">
      {@html string.progressRemember}
    </p>
  </div>

  <MainAction delay="1800" changePageTo="Test" forceSmallClickArea="true"
    >{string.wantToStart}</MainAction
  >
</section>
