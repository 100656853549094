<!--  
	menu: resetting the app
-->
<script>
  // imports
  // ------------------------------------------------------------------------
  import state from "../Data/state.js";
  import string from "../Data/translations.js";
  import Menu from "../UI/Menu.svelte";

  // purpose:		resets all the app data
  // returns:		dispatches a 'appReset' event and redirects to Hello page
  // ------------------------------------------------------------------------
  function resetApp() {
    window.dispatchEvent(new CustomEvent("appReset"));

    localStorage.clear();
    state.setSet(1);
    state.setStep(0);
    state.setPage("Hello");
  }

  // Add Umami Analytics script
    const script = document.createElement('script');
    script.defer = true;
    script.src = "https://cloud.umami.is/script.js";
    script.setAttribute("data-website-id", "38af22fa-8c4c-4122-ae72-767c084a366a");
    document.body.appendChild(script);
</script>

<Menu
  menuId="ResetApp"
  contentClass="menu-article menu-question"
  buttonBackLabel={string.cancel}
  buttonNextLabel={string.iAmSure}
  buttonNextAction={() => resetApp()}
>
  <h2>{string.resetQuestion}</h2>
  <p>{@html string.resetNote}</p>
</Menu>
